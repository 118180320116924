import React from "react";
import * as classes from "./ButtonPager.module.css";
import { Link } from "gatsby";
import arrow from "../../images/icon-arrow-white.svg";

const ButtonPager = ({ title, link, image }) => {
  return (
    <div className={classes.wrapper}>
      <Link className={classes.link} to={link}>
        {title}
        <img className={classes.img} src={image} alt="" />
      </Link>

      <img className={classes.arrow} src={arrow} alt="arrow" />
    </div>
  );
};

export default ButtonPager;
