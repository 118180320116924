import React from "react";
import { graphql } from "gatsby";
import styled from "@emotion/styled";
import Seo from "../components/seo";
import HeadingWithSub from "../components/HeadingWithSub";

import ButtonPager from "../components/ButtonPager/ButtonPager";
import translateImg from "../images/icon-translate.svg";

import Slide from "../components/CarouselSlider/Slide";

import { A11y } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

const HomeContainer = styled.div`
  .tours {
    margin: 50px 0 30px;
  }

  .schedule {
    margin-bottom: 30px;
  }

  .collection {
    margin-bottom: 30px;
  }

  .guides {
    margin-bottom: 30px;
  }

  .map {
    display: block;
    margin-top: 30px;
  }

  .slick-slide img {
    opacity: 1 !important;
  }
`;

const IndexPage = ({ data }) => {

  return (
    <>
      <HomeContainer>
        <Seo title="Home" />

        <ButtonPager
          title="Visitor Guides & Maps"
          link="/guides-maps"
          image={translateImg}
        />

        <ButtonPager title="Accessibility Guide" link="/accessibility-guide" />

        <ButtonPager title="The Collection" link="/collection" />

        <ButtonPager title="Artist Guides" link="/artistguides" />

        

        <HeadingWithSub
          main={"For Families"}
          h2={true}
          backArrow={true}
          link="/for-families"
        />

        <Swiper
          modules={[A11y]}
          spaceBetween={5}
          slidesPerView={1.5}
          onSwiper={(swiper) => console.log(swiper)}
          onSlideChange={() => console.log('slide change')}
        >
          <SwiperSlide>
            <Slide
              title="The Broad Scavenger Hunt"
              link="/scavenger-hunt"
              linkText="Go On a Quest for Art"
              img={data.scavengerHuntImage}
            />
          </SwiperSlide>
          <SwiperSlide>
            <Slide
              title="Tips for Families"
              link="/tips-for-families"
              linkText="Things to remember during your visit"
              img={data.tipForFamiliesImage}
            />
          </SwiperSlide>
          <SwiperSlide>
            <Slide
              title="Let's Look at Art Together"
              link="/look-at-art-together"
              linkText="Kids Tour"
              img={data.artTogetherImage}
            />
          </SwiperSlide>
          <SwiperSlide>
            <Slide
              title="Explore The Broad Collection"
              link="/explore-the-broad"
              linkText="Fill in the blanks"
              img={data.exploreImage}
            />
          </SwiperSlide>  
          <SwiperSlide>
            <Slide
              title="Looking with LeVar Burton"
              link="/kids-audio-tour/?families=1"
              linkText="Art descriptions and questions for kids"
              img={data.levarImage}
            />
          </SwiperSlide>
          <SwiperSlide>
            <Slide
              title="General Questions to Ask Kids"
              link="/general-questions-kids"
              linkText="Get your kids talking"
              img={data.generalqImage}
            />
          </SwiperSlide>
        </Swiper>

        <HeadingWithSub
          main={"Audio Tours"}
          h2={true}
          backArrow={true}
          link="/audio"
        />

        <Swiper
          // install Swiper modules
          modules={[A11y]}
          spaceBetween={5}
          slidesPerView={1.5}
          onSwiper={(swiper) => console.log(swiper)}
          onSlideChange={() => console.log('slide change')}
        >
          {data.allTaxonomyTermTours.edges.map(({ node: tour_term }, index) => {
            return (
              <SwiperSlide>
                <Slide
                  key={index}
                  title={tour_term.name}
                  mobileTitle={tour_term.field_mobile_display_name}
                  useMobile={true}
                  link={tour_term.path.alias}
                  img={tour_term.rel.image[0].localFile}
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </HomeContainer>
    </>
  );
};

export default IndexPage;

export const query = graphql`
  query {
    collectionImage: file(
      relativePath: { eq: "the-collection-blue-dog-home.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, width: 82)
      }
    }
    tipForFamiliesImage: file(
      relativePath: { eq: "1_tips-for-fam_750_5252.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    scavengerHuntImage: file(
      relativePath: { eq: "4_TB Scavenger Hunt_753_1324.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    artTogetherImage: file(
      relativePath: { eq: "2_Lets Look at Art Together_753_1351.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    exploreImage: file(relativePath: { eq: "3_ExploreTBC_754_3963.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    levarImage: file(
      relativePath: { eq: "5_Looking with LeVar_753_7989.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    generalqImage: file(relativePath: { eq: "6_General Questions_Kids.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    allTaxonomyTermTours(
      sort: { fields: [field_mobile_app_menu_weight], order: DESC }
    ) {
      edges {
        node {
          name
          drupal_id
          field_mobile_app_menu_weight
          field_mobile_display_name
          path {
            alias
          }
          rel: relationships {
            image: field_lightbox_image {
              localFile {
                relativePath
                publicURL
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED)
                }
              }
            }
          }
        }
      }
    }
  }
`;
