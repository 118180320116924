import React from "react";
import { Link } from "gatsby";
import * as classes from "./Slide.module.css";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const Slide = ({
  title,
  mobileTitle,
  link,
  linkText,
  img,
  origin = "home",
  useMobile = false,
}) => {
  return (
    <Link to={link} state={{ originalPath: origin }}>
      <div className={classes.wrapper}>
        <GatsbyImage
          className={classes.img}
          image={getImage(img)}
          alt={useMobile ? mobileTitle : title}
        />
        <div className={classes.content}>
          <p className={classes.title}>{useMobile ? mobileTitle : title}</p>
          {linkText ? <p className={classes.bold}>{linkText}</p> : null}

          {title === "Mobile App Audio Tour" && (
            <p className={classes.bold}>Audio Tour</p>
          )}

          {title === "Mobile App Kids Tour" && (
            <p className={classes.bold}>Kids Tour</p>
          )}

          {title === "Mobile App Architecture Tour" && (
            <p className={classes.bold}>Architecture Tour</p>
          )}
        </div>
      </div>
    </Link>
  );
};

export default Slide;
